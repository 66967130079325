import React from "react";

const Header = () => {
  return (
    <header className="bg-blue-500 p-4 flex justify-between items-center">
      <div className="flex items-center">
        <h1 className="text-white text-2xl font-bold">Vacance en Inde</h1>
      </div>
      <div className="flex items-center">
        <p className="text-white mr-4">Contactez-nous : +91 8630770389</p>
        <a
          href="https://voyagesinde.com/contact"
          className="text-white border border-white py-2 px-4 rounded-md transition duration-300 hover:bg-white hover:text-blue-500"
        >
          Réserver maintenant
        </a>
      </div>
    </header>
  );
};

export default Header;
