import React from "react";

const HeroSection = () => {
  return (
    <section
      className="h-screen bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: "url('hero.jpg')" }}
    >
      <div className="text-center text-white">
        <h2 className="text-4xl font-bold mb-4">
          Bienvenue chez Vacance en Inde
        </h2>
        <p className="text-lg">
          Voyagez en Inde avec nous, explorez les merveilles de l'Inde avec
          notre guide francophone
        </p>
      </div>
    </section>
  );
};

export default HeroSection;
