import AboutUsPage from "./AboutUsPage";
import FeaturesPage from "./FeaturesPage";
import Footer from "./Footer";
import Header from "./Header";
import HeroSection from "./HeroSection";
import ServicesPage from "./ServicesPage";
function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <FeaturesPage />
      <ServicesPage />
      <AboutUsPage />
      <Footer />
    </div>
  );
}

export default App;
