import React from "react";
import { FaCar, FaHotel, FaLanguage } from "react-icons/fa";

const ServicesPage = () => {
  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl font-bold mb-6 text-center">Services Proposés</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Service 1 */}
        <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-md">
          <FaCar className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Location de Voitures</h3>
          <p className="text-center">
            Explorez l'Inde en toute simplicité en louant un véhicule fiable et
            confortable.
          </p>
        </div>

        {/* Service 2 */}
        <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-md">
          <FaHotel className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Réservation d'Hôtels</h3>
          <p className="text-center">
            Trouvez et réservez des hébergements adaptés à vos préférences et à
            votre budget.
          </p>
        </div>

        {/* Service 3 */}
        <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-md">
          <FaLanguage className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">
            Guides Professionnels Parlant Français
          </h3>
          <p className="text-center">
            Découvrez l'Inde avec l'aide de guides compétents et parlant
            couramment le français.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
