// Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black py-4 text-center">
      <p className="text-sm text-white">
        Vacance en Inde est une société sœur de Voyagesinde &copy; 2023
      </p>
    </footer>
  );
};

export default Footer;
