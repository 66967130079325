// AboutUsPage.js
import React from "react";
import { FaStar, FaHeadset, FaCheck, FaDollarSign } from "react-icons/fa";

const AboutUsPage = () => {
  return (
    <div className="container mx-auto py-8">
      <h2 className="text-4xl font-bold mb-12 text-center">À propos de nous</h2>

      {/* About Company */}
      <div className="mb-12">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-4">À propos de notre entreprise</h2>
          <p className="mb-4">
            Chez Vacance en Inde, nous sommes spécialisés dans la création
            d'expériences de voyage exceptionnelles en Inde. Notre entreprise s'engage à offrir des
            forfaits de voyage minutieusement conçus, comprenant la location de voitures, les réservations d'hôtels
            et des visites guidées, garantissant ainsi la commodité, le confort et
            l'accessibilité pour les voyageurs explorant les paysages variés et
            le riche patrimoine culturel de l'Inde.
          </p>
          <p className="mb-4">
            Notre équipe se compose de professionnels chevronnés et de guides compétents
            parlant plusieurs langues, dont le français, pour offrir des visites personnalisées et enrichissantes. Nous garantissons un support client 24h/24, des options de paiement sécurisées et des prix compétitifs
            sans compromettre la qualité du service.
          </p>
          <p>
            Que vous recherchiez des aventures époustouflantes dans l'Himalaya, des retraites sereines à Goa, ou des immersions culturelles au Rajasthan, notre expertise et notre dévouement garantissent un voyage mémorable et sans tracas. Découvrez
            la beauté et les merveilles de l'Inde avec Vacance en Inde, votre compagnon de voyage de confiance.
          </p>
        </div>
      </div>

      {/* Services */}
      <h2 className="text-4xl font-bold mb-12 text-center">Nos Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Service 1 */}
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
          <FaStar className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Assurance Qualité</h3>
          <p className="text-center">
            Nous garantissons une qualité irréprochable dans nos services pour améliorer votre expérience de voyage.
          </p>
        </div>

        {/* Service 2 */}
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
          <FaHeadset className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Support Client 24/7</h3>
          <p className="text-center">
            Notre équipe de support dédiée est disponible 24h/24 pour vous assister.
          </p>
        </div>

        {/* Service 3 */}
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
          <FaCheck className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Guides Expérimentés</h3>
          <p className="text-center">
            Explorez l'Inde avec nos guides expérimentés qui assurent un voyage enrichissant.
          </p>
        </div>

        {/* Service 4 */}
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
          <FaDollarSign className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Prix Compétitifs</h3>
          <p className="text-center">
            Nous proposons des prix compétitifs sans compromettre la qualité du service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
