// FeaturesPage.js
import React from "react";
import { FaStar, FaDollarSign, FaLock, FaHeadset } from "react-icons/fa";

const FeaturesPage = () => {
  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl font-bold mb-6 text-center">Nos Principales Caractéristiques</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Feature 1 */}
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
          <FaStar className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Meilleures Sélections</h3>
          <p className="text-center">
            Sélection des meilleures destinations et expériences soigneusement élaborée pour votre voyage.
          </p>
        </div>

        {/* Feature 2 */}
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
          <FaDollarSign className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Prix Raisonnable</h3>
          <p className="text-center">
            Tarification abordable et compétitive sans compromettre la qualité.
          </p>
        </div>

        {/* Feature 3 */}
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
          <FaLock className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Paiements Sécurisés</h3>
          <p className="text-center">
            Options de paiement sûres et sécurisées pour des transactions sans tracas.
          </p>
        </div>

        {/* Feature 4 */}
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
          <FaHeadset className="text-5xl text-blue-500 mb-4" />
          <h3 className="text-2xl font-semibold mb-2">Support 24/7</h3>
          <p className="text-center">
            Support client disponible 24h/24 pour vous assister à tout moment.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeaturesPage;
